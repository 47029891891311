import Image from './framework/Image';
import Pressable from './framework/Pressable';
const logo = require('../../assets/logo.png');
const splashLogo = require('../../assets/splash.png');

type iLogo = {
  useSplash?: boolean;
  width?: number;
  height?: number;
  onNav?: () => void;
}
const Logo = ({width = 100, height = 100, useSplash = false, onNav}: iLogo) => {

  const getLogoImage = () => {
    return (
      <Image
        source={useSplash === true ? splashLogo : logo}
        alt={'logo'}
        width={width}
        height={height}
      />
    )
  }

  if (!onNav) {
    return getLogoImage();
  }

  return (
    <Pressable onLongPress={() => onNav && onNav()} >
      {getLogoImage()}
    </Pressable>
  )
}

export default Logo;
