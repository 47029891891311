import View from '../../../components/framework/View';
import {studentAbsenceScreenStyle} from '../../../AppStyles';
import Text from '../../../components/framework/Text';
import {useState} from 'react';
import HStack from '../../../components/framework/HStack';
import Button from '../../../components/framework/Button';
import {Icons} from '../../../components/framework/Icon';
import FormControlErrorDiv from '../../../components/FomControlErrorDiv';

type iStudentEmailSearchPanel = {
  onSearch: (keyword: string) => void;
  errorMsg?: string;
  isLoading?: boolean;
}
const StudentEmailSearchPanel = ({onSearch, errorMsg, isLoading}: iStudentEmailSearchPanel) => {
  const [studentUsername, setStudentUsername] = useState('');

  const addNumber = (number: string) => {
    setStudentUsername(`${studentUsername}${number}`);
  }

  const removeLastNumber = () => {
    setStudentUsername(`${studentUsername}`.slice(0, -1));
  }

  return (
    <View>
      <HStack style={[studentAbsenceScreenStyle.searchInputWrapper]}>
        <Text style={[studentAbsenceScreenStyle.searchInputDiv, {width: '55%'}]}>
          {`${studentUsername}`.trim() === '' ? 'Your username' : `${studentUsername}`}
        </Text>
        <Text style={[studentAbsenceScreenStyle.searchInputDiv, {backgroundColor: '#ccc', width: '35%'}]}>
          @mentonegirls.vic.edu.au
        </Text>
        <Button
          colorScheme={'primary'}
          onPress={() => removeLastNumber()}
          style={[studentAbsenceScreenStyle.searchBtn, {width: '10%'}]}
        >
          <Icons.ArrowBackIcon style={{color: 'white'}}/>
        </Button>
      </HStack>
      <FormControlErrorDiv errorMsg={errorMsg} />
      {[
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', `'`],
        ['Z', 'X', 'C', 'V', 'B', 'N', 'M', '-', '_', '.'],
      ].map((row, rowIndex) => {
        return (
          <HStack justifyContent={'space-between'} key={rowIndex}>
            {row.map((str, index) => {
              return (
                <Button
                  colorScheme={'primary'}
                  variant={'unstyled'}
                  key={index}
                  isLoading={isLoading}
                  onPress={() => addNumber(`${str}`)}
                  style={[
                    studentAbsenceScreenStyle.searchInputBtnWhite,
                    studentAbsenceScreenStyle.searchInputBtn,
                    {width: '8%'}
                  ]}
                >
                  <Text style={{fontSize: 20}}>{str}</Text>
                </Button>
              )
            })}
          </HStack>
        )
      })}

      <HStack justifyContent={'space-between'}>
        <Button
          colorScheme={'danger'}
          startIcon={<Icons.CloseIcon />}
          onPress={() => setStudentUsername('')}
          isLoading={isLoading}
          style={[
            studentAbsenceScreenStyle.searchInputBtn,
            {width: '49%'}
          ]}
        >
          <Text style={{fontWeight: 'bold', color: 'white', fontSize: 20}}>Clear</Text>
        </Button>

        <Button
          colorScheme={'primary'}
          startIcon={<Icons.SearchIcon />}
          onPress={() => onSearch(studentUsername)}
          isLoading={isLoading}
          style={[
            studentAbsenceScreenStyle.searchInputBtn,
            {width: '49%'}
          ]}
        >
          <Text style={{fontWeight: 'bold', color: 'white', fontSize: 20}}>Submit</Text>
        </Button>
      </HStack>
    </View>
  )
}

export default StudentEmailSearchPanel;
