import {AlertDialog, Center} from 'native-base';
import {
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader
} from '../../components/framework/AlertDialog';
import ButtonGroup from '../../components/framework/ButtonGroup';
import Button from '../../components/framework/Button';
import {Icons} from '../../components/framework/Icon';
import {useRef, useState} from 'react';
import {useAppDispatch} from '../../redux/makeReduxStore';
import LocalStorageService from '../../services/LocalStorageService';
import {setApp} from '../../redux/reduxers/app.slice';
import Heading from '../../components/framework/Heading';
import DeviceInfo from '../../components/DeviceInfo';
import VStack from '../../components/framework/VStack';
import HStack from '../../components/framework/HStack';
import Urls from '../../helper/Urls';

const DeviceInfoScreen = ({navigation}) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const cancelRef = useRef(null);
  const dispatch = useAppDispatch();

  const clear = () => {
    LocalStorageService.clearAll()
      .then(() => {
        dispatch(setApp({}))
      })
  }

  const getConfirmPopup = () => {
    if (isConfirming !== true) {
      return null;
    }
    return (
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isConfirming}
        onClose={() => setIsConfirming(false)}
      >
        <AlertDialogContent>
          <AlertDialogCloseButton />
          <AlertDialogHeader>
            Reset app...
          </AlertDialogHeader>
          <AlertDialogBody>
            You are about to reset this app, are you sure to continue?
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                leftIcon={<Icons.CloseIcon />}
                variant={'unstyled'}
                colorScheme={'coolGray'}
                onPress={() => setIsConfirming(false)}
                ref={cancelRef}>
                Cancel
              </Button>
              <Button
                leftIcon={<Icons.DeleteIcon />}
                variant={'solid'}
                colorScheme={'danger'}
                onPress={() => clear()}
                ref={cancelRef}>
                Reset
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

  return (
    <Center marginTop={6}>
      <VStack width={'80%'}>
        <Heading>Device Info</Heading>
        <DeviceInfo />
        <HStack space={4}>
          <Button colorScheme={'primary'} leftIcon={<Icons.HomeIcon />} onPress={() => navigation.push(Urls.HOME)}>
            Home
          </Button>
          <Button colorScheme={'danger'} leftIcon={<Icons.DeleteIcon />} onPress={() => setIsConfirming(true)}>
            Reset
          </Button>
        </HStack>
      </VStack>
      {getConfirmPopup()}
    </Center>
  )
}

export default DeviceInfoScreen;
