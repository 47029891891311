import View from '../../components/framework/View';
import iCampusDisplaySlide from '../../types/CampusDisplay/iCampusDisplaySlide';
import {useState, useEffect, useRef} from 'react';
import StyleSheet from '../../components/framework/StyleSheet';
import ImageBackground from '../../components/framework/ImageBackground';
import { BlurView } from 'expo-blur';
import {Image, Pressable} from 'native-base';
import {Animated} from 'react-native';

type iCampusDisplayCarouselSlides = {
  slides: iCampusDisplaySlide[];
  onNavToSelector?: () => void
}

const CampusDisplayCarouselSlides = ({slides, onNavToSelector}: iCampusDisplayCarouselSlides) => {

  const slideShowingTime = 3000;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentSlideDownCount, setCurrentSlideDownCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const fadeAnim = useRef(new Animated.Value(0)).current;

  const styles = StyleSheet.create({
    carouselWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
    },
    carouselItem: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'lightgray',
      position: 'relative',
    },
    carouselItemBg: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    blurView: {
      ...StyleSheet.absoluteFillObject,
    },
    carouselItemContent: {
      width: '50%',
      height: '50%',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    imageContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      paddingTop: '80px',
      paddingBottom: '80px',
    },
    image: {
      width: '70%',
      height: '100%',
    },
    loadingBarWrapper: {
      width: '100%',
      height: '1px',
      position: 'absolute',
      bottom: '0px',
      backgroundColor: 'rgb(0, 82, 137)',
    },
    loadingBar: {
      height: '1px',
      backgroundColor: 'rgb(229, 39, 37)',
    }
  })

  useEffect(() => {
    const fadeIn = () => {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 500, // Fade in duration in milliseconds
        useNativeDriver: true,
      }).start();
    };

    const fadeOut = () => {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 500, // Fade out duration in milliseconds
        useNativeDriver: true,
      }).start();
    };

    const rotateSlides = () => {
      if (currentSlideDownCount < slideShowingTime) {
        setCurrentSlideDownCount( currentSlideDownCount + 1);
        return;
      }

      setCurrentSlideDownCount(0);
      fadeOut();
      setTimeout(() => {
        setCurrentSlideIndex(prevIndex => (prevIndex + 1) % slides.length);
        setIsInitialLoad(false);
        fadeIn();
      }, 500); // Add a delay to ensure fadeOut animation finishes before changing slides
    };

    const rotationInterval = setInterval(rotateSlides, 1); // Interval for rotating slides

    return () => {
      clearInterval(rotationInterval);
    };
  }, [slides, currentSlideDownCount]);

  return (
    <Pressable onLongPress={() => {
      onNavToSelector && onNavToSelector();
    }} style={styles.carouselWrapper}>
      {slides.map((slide, index) => {
        if (currentSlideIndex !== index || `${slide.Asset?.url || ''}`.trim() === '') {
          return null;
        }
        return (
          <Animated.View style={[styles.carouselItem, {opacity: isInitialLoad ? 1 : fadeAnim}]} key={slide.id} >
            <ImageBackground
              source={{uri: `${slide.Asset?.url || ''}`.trim().replace('.mp4', '.jpg')}}
              style={styles.carouselItemBg}
              resizeMode="cover"
            />
            {
              slide.settings?.isFullScreen !== true ? (
                <>
                  <BlurView style={styles.blurView} intensity={100} />

                  <View style={styles.imageContainer}>
                    <Image
                      source={{ uri: slide.Asset?.url || '' }}
                      style={styles.image}
                      resizeMode="contain"
                      alt={'Videos can NOT be played yet.'}/>
                  </View>
                </>
              ) : null
            }
          </Animated.View>
        )
      })}
      <View style={styles.loadingBarWrapper}>
        <View style={{...styles.loadingBar, width: `${(currentSlideDownCount / slideShowingTime) * 100}%`}}></View>
      </View>
    </Pressable>
  );
}

export default CampusDisplayCarouselSlides;
