import Text from './framework/Text';
import {studentAbsenceScreenStyle} from '../AppStyles';

type iFormControlErrorDiv = {
  errorMsg?: string;
}
const FormControlErrorDiv = ({errorMsg}: iFormControlErrorDiv) => {
  if (`${errorMsg || ''}`.trim() === '') {
    return null;
  }
  return (
    <Text style={[studentAbsenceScreenStyle.searchInputError]}>{errorMsg}</Text>
  )
};

export default FormControlErrorDiv;
