import * as SentryLib from 'sentry-expo';

const init = () => {
  const dsn = `${process.env.REACT_APP_SENTRY_DSN || ''}`.trim();
  if (dsn === '') {
    return;
  }

  SentryLib.init({
    dsn,
    tracesSampleRate: 1.0,
    enableInExpoDevelopment: true,
    // debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  });
}

const Sentry = {
  init
}

export default Sentry;
