import View from '../../components/framework/View';
import Center from '../../components/framework/Center';
import {useEffect, useState} from 'react';
import LocalStorageService, {
  LOCAL_STORAGE_SELECTED_DISPLAY_LOCATION_ID
} from '../../services/LocalStorageService';
import Toaster from '../../components/common/Toaster';
import {useToast} from 'native-base';
import Text from '../../components/framework/Text';
import CampusDisplayLocationSelector from './CampusDisplayLocationSelector';
import VStack from '../../components/framework/VStack';
import {studentAbsenceScreenStyle} from '../../AppStyles';
import {Icons} from '../../components/framework/Icon';
import Button from '../../components/framework/Button';
import Urls from '../../helper/Urls';
import HStack from '../../components/framework/HStack';
import Spinner from '../../components/framework/Spinner';
import CampusDisplayCarousel from './CampusDisplayCarousel';

const CampusDisplayScreen = ({navigation}) => {
  const toaster = useToast();
  const [locationId, setLocationId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    LocalStorageService.getItem(LOCAL_STORAGE_SELECTED_DISPLAY_LOCATION_ID)
      .then(resp => {
        if (isCanceled) { return }
        setLocationId(resp || null);
      })
      .catch(err => {
        if (isCanceled) { return }
        Toaster.showApiErr(err, toaster);
      })
      .finally(() => {
        if (isCanceled) { return }
        setIsLoading(false);
      })
    return () => {
      isCanceled = true;
    }
  }, [])

  useEffect(() => {
    if (`${locationId || ''}`.trim() === '') {
      return;
    }
    LocalStorageService.setItem(LOCAL_STORAGE_SELECTED_DISPLAY_LOCATION_ID, locationId)
  }, [locationId])

  const getContent = () => {
    if (isLoading === true) {
      return (
        <Center flex={1}>
          <Spinner />
        </Center>
      )
    }

    if (`${locationId || ''}`.trim() === '') {
      return (
        <Center flex={1}>
          <VStack>
            <Center>
              <View><Text>Please select a location:</Text></View>
              <View><CampusDisplayLocationSelector
                selectedLocationId={locationId}
                onLocationSelected={(locId) => setLocationId(locId)}
              /></View>

              <Button
                colorScheme={'lightBlue'}
                variant={'link'}
                style={[studentAbsenceScreenStyle.styleSelectBtn]}
                onPress={() => navigation.push(Urls.HOME)}
              >
                <HStack alignItems={'center'} space={2}>
                  <Icons.CloseIcon size={3}/>
                  <Text>Cancel</Text>
                </HStack>
              </Button>
            </Center>
          </VStack>
        </Center>
      )
    }

    return <CampusDisplayCarousel locationId={locationId} onNavToSelector={() => {
      LocalStorageService.removeItem(LOCAL_STORAGE_SELECTED_DISPLAY_LOCATION_ID).then(() => {
        setLocationId(null);
      })
    }}/>;
  }

  return getContent()
};


export default CampusDisplayScreen;
