import {useSelector} from 'react-redux';
import {RootState} from '../../redux/makeReduxStore';
import View from '../framework/View';
import Text from '../framework/Text';

const TestDiv = () => {
  const app = useSelector((state: RootState) => state.app);

  if (app.isProd === true) {
    return null;
  }

  return <View style={{ position: 'absolute', bottom: 0, right: 0, padding: 12, backgroundColor: 'red'}}><Text style={{color: 'white', fontWeight: 'bold'}}>TEST MODE</Text></View>
}

export default TestDiv;
