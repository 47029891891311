import Text from './framework/Text';
import {useSelector} from 'react-redux';
import {RootState} from '../redux/makeReduxStore';
import View from './framework/View';
import FlatList from './framework/FlatList';
import {useEffect, useState} from 'react';
import LocalStorageService, {
  LOCAL_STORAGE_API_END_POINT,
} from '../services/LocalStorageService';
import {useToast} from 'native-base';
import Toaster from './common/Toaster';
import {DEFAULT_END_POINT} from '../services/AppService';
import Spinner from './framework/Spinner';

const DeviceInfo = () => {
  const toaster = useToast();
  const {deviceInfo} = useSelector((state: RootState) => state.app);
  const [isLoading, setIsLoading] = useState(false);
  const [endPointUrl, setEndPointUrl] = useState('');

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    LocalStorageService.getItem(LOCAL_STORAGE_API_END_POINT)
      .then(resp => {
        if (isCanceled) { return }
        setEndPointUrl(resp || DEFAULT_END_POINT);
      })
      .catch(err => {
        if (isCanceled) { return }
        Toaster.showApiErr(err, toaster);
      })
      .finally(() => {
        if (isCanceled) { return }
        setIsLoading(false);
      })
    return () => {
      isCanceled = true;
    }
  }, [])

  const getRow = (fieldName: string, label: string, value: string) => {
    return (
      <View flexShrink={1} marginBottom={4} borderBottomColor={'muted.50'} borderBottomStyle={'solid'}>
        <Text fontSize={16} fontWeight={'bold'}>{label}</Text>
        <Text>{value}</Text>
      </View>
    )
  }

  const getContent = () => {
    if (isLoading) {
      return (
        <Spinner />
      )
    }

    return (
      <FlatList
        data={[
          {fieldName: 'endPointUrl', label: 'EndPoint Url', value: endPointUrl},
          {fieldName: 'deviceId', label: 'Device ID', value: ('deviceId' in deviceInfo) ? deviceInfo.deviceId : ''},
          {fieldName: 'name', label: 'Device Name', value: ('name' in deviceInfo) ? deviceInfo.name : ''},
          {fieldName: 'description', label: 'Description', value: ('description' in deviceInfo) ? deviceInfo.description : ''},
          {fieldName: 'make', label: 'Make', value: ('make' in deviceInfo) ? deviceInfo.make : ''},
          {fieldName: 'model', label: 'Model', value: ('model' in deviceInfo) ? deviceInfo.model : ''},
          {fieldName: 'location', label: 'Location', value: ('location' in deviceInfo) ? deviceInfo.location : ''},

        ]}
        renderItem={({item}) => getRow(item.fieldName, item.label, item.value)}
      />
    )
  }

  return (
    <View marginTop={4} marginBottom={4}>
      {getContent()}
    </View>
  )
}

export default DeviceInfo;
