import {Icons} from '../components/framework/Icon';
import Center from '../components/framework/Center';
import Logo from '../components/Logo';
import VStack from '../components/framework/VStack';
import Pressable from '../components/framework/Pressable';
import Text from '../components/framework/Text';
import HStack from '../components/framework/HStack';
import Urls from '../helper/Urls';
import {screenStyle} from '../AppStyles';
import LocalStorageService, {LOCAL_STORAGE_SELECTED_SCREEN} from '../services/LocalStorageService';

const HomeScreen = ({navigation}) => {

  const getTitle = (icon: any, title: string, onNav: () => void, description?: string) => {
    return (
      <Pressable
        rounded="8"
        overflow="hidden"
        borderWidth="1"
        borderColor="coolGray.300"
        maxW="296"
        shadow="3"
        bg="coolGray.100"
        p="10"
        onPress={() => onNav()}
      >
        {icon}
        <Text  style={[screenStyle.h5]} marginTop={2}>{title}</Text>
        {`${description || ''}`.trim() === '' ? null : <Text style={[screenStyle.small]} marginTop={2}>{description}</Text>}
      </Pressable>
    )
  }

  return (
    <Center flex={1}>
      <VStack>
        <HStack justifyContent="center">
          <Logo useSplash width={200} height={200} onNav={() => {
            LocalStorageService.removeItem(LOCAL_STORAGE_SELECTED_SCREEN)
              .then(() => {
                navigation.push(Urls.DEVICE_INFO)
              })
          }}/>
        </HStack>
        <HStack space={3} justifyContent="center">
          {getTitle(<Icons.SignIn size={'5xl'}/>, 'Sign In / Sign Out', () => {
            LocalStorageService.setItem(LOCAL_STORAGE_SELECTED_SCREEN, Urls.STUDENT_ABSENCE)
              .then(() => {
                navigation.push(Urls.STUDENT_ABSENCE)
              })
          }, 'The Early Sign Out or Late Sign In')}

          {getTitle(<Icons.PlayIcon size={'5xl'}/>, 'Campus Display', () => {
            LocalStorageService.setItem(LOCAL_STORAGE_SELECTED_SCREEN, Urls.CAMPUS_DISPLAY)
              .then(() => {
                navigation.push(Urls.CAMPUS_DISPLAY)
              })
          }, 'Displays around the whole campus')}
        </HStack>
      </VStack>
    </Center>
  )
}

export default HomeScreen
