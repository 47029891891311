import {createNativeStackNavigator} from '@react-navigation/native-stack';
import StudentAbsenceScreen from './screens/StudentAbsense/StudentAbsenceScreen';
import Urls from './helper/Urls';
import DeviceInfoScreen from './screens/Settings/DeviceInfoScreen';
import HomeScreen from './screens/HomeScreen';
import {useSelector} from 'react-redux';
import {RootState} from './redux/makeReduxStore';
import CampusDisplayScreen from './screens/CampusDisplay/CampusDisplayScreen';


const Stack = createNativeStackNavigator();

const Router = () => {
  const app = useSelector((state: RootState) => state.app);

  return (
    <Stack.Navigator initialRouteName={app.selectedScreen}>
      {/*{getSelectedScreen()}*/}
      <Stack.Screen
        name={Urls.HOME}
        component={HomeScreen}
        options={{title: 'HOME', header: () => null}}
      />
      <Stack.Screen
        name={Urls.STUDENT_ABSENCE}
        component={StudentAbsenceScreen}
        options={{title: 'Student Absence', header: () => null}}
      />
      <Stack.Screen
        name={Urls.CAMPUS_DISPLAY}
        component={CampusDisplayScreen}
        options={{title: 'Campus Display', header: () => null}}
      />
      <Stack.Screen
        name={Urls.DEVICE_INFO}
        component={DeviceInfoScreen}
        options={{title: 'Device Info'}}
      />
    </Stack.Navigator>
  )
}

export default Router;
