import Logo from '../../components/Logo';
import Text from '../../components/framework/Text';
import VStack from '../../components/framework/VStack';
import Center from '../../components/framework/Center';
import { Animated, Easing } from 'react-native';
import {useEffect, useRef} from 'react';


const CampusDisplayCarouselDefaultSlide = () => {

  const spinValue = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    const spin = () => {
      spinValue.setValue(0);
      Animated.timing(spinValue, {
        toValue: 1,
        duration: 15000, // 5 seconds
        easing: Easing.linear,
        useNativeDriver: true,
      }).start(() => spin());
    };

    spin();
  }, [spinValue]);

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  return (
    <Animated.View style={[
      {
        transform: [{ rotateY: spin }],
        backgroundColor: 'transparent',
      },
    ]}>

      <VStack>
        <Center flex={1}>
          <Logo width={200} height={200}/>
          <Text fontSize={24}>Campus Display</Text>
        </Center>
      </VStack>
    </Animated.View>
  )
}

export default CampusDisplayCarouselDefaultSlide;
