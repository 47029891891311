import View from '../../components/framework/View';
import CampusDisplayCarouselDefaultSlide from './CampusDisplayCarouselDefaultSlide';
import {useEffect, useState} from 'react';
import Toaster from '../../components/common/Toaster';
import iCampusDisplaySlide from '../../types/CampusDisplay/iCampusDisplaySlide';
import {useToast} from 'native-base';
import CampusDisplayLocationService from '../../services/CampusDisplay/CampusDisplayLocationService';
import CampusDisplaySlideService from '../../services/CampusDisplay/CampusDisplaySlideService';
import Spinner from '../../components/framework/Spinner';
import CampusDisplayCarouselSlides from './CampusDisplayCarouselSlides';

type iCampusDisplayCarousel = {
  locationId: string;
  onNavToSelector?: () => void;
}
const CampusDisplayCarousel = ({locationId, onNavToSelector}: iCampusDisplayCarousel) => {
  const toaster = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [slides, setSlides] = useState<iCampusDisplaySlide[]>([]);

  useEffect(() => {
    let isCanceled = false;

    const getData = async () => {
      const locationList = await CampusDisplayLocationService.getAll({
        where: JSON.stringify({id: locationId, isActive: true})
      });

      const locations = locationList.data || [];
      if ( locations.length <= 0 || `${locations[0].displayId || ''}`.trim() === '') {
        return;
      }

      const slides = await CampusDisplaySlideService.getAll({
        where: JSON.stringify({displayId: locations[0].displayId, isActive: true}),
        sort: 'sortOrder:ASC',
        include: 'Asset',
        perPage: 999999,
      });
      return slides.data || [];
    }

    setIsLoading(true);
    getData().then(resp => {
        if (isCanceled) { return }
        setSlides(resp);
      })
      .catch(err => {
        if (isCanceled) { return }
        Toaster.showApiErr(err, toaster);
      })
      .finally(() => {
        if (isCanceled) { return }
        setIsLoading(false);
      })
    return () => {
      isCanceled = true;
    }
  }, [])

  if (isLoading === true) {
    return <View><Spinner /></View>
  }

  if (slides.length === 0) {
    return (
      <CampusDisplayCarouselDefaultSlide />
    );
  }

  return <CampusDisplayCarouselSlides slides={slides} onNavToSelector={onNavToSelector} />
}

export default CampusDisplayCarousel;
