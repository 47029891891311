import View from '../../../components/framework/View';
import Text from '../../../components/framework/Text';
import {useState} from 'react';
import HStack from '../../../components/framework/HStack';
import {studentAbsenceScreenStyle} from '../../../AppStyles';
import Button from '../../../components/framework/Button';
import {Icons} from '../../../components/framework/Icon';
import FormControlErrorDiv from '../../../components/FomControlErrorDiv';

type iStudentIDSearchPanel = {
  onSearch: (keywords: string) => void;
  errorMsg?: string;
  isLoading?: boolean;
}
const StudentIDSearchPanel = ({onSearch, errorMsg, isLoading = false}: iStudentIDSearchPanel) => {

  const [studentId, setStudentId] = useState('');

  const addNumber = (number: string) => {
    setStudentId(`${studentId}${number}`);
  }

  const removeLastNumber = () => {
    setStudentId(`${studentId}`.slice(0, -1));
  }

  return (
    <View>
      <HStack style={[studentAbsenceScreenStyle.searchInputWrapper, {flexWrap: 'nowrap'}]}>
        <Text style={[studentAbsenceScreenStyle.searchInputDiv, {width: '90%'}]}>
          {`${studentId}`.trim() === '' ? 'Please provide your ID number' : `${studentId}`}
        </Text>
        <Button
          colorScheme={'primary'}
          onPress={() => removeLastNumber()}
          style={[studentAbsenceScreenStyle.searchBtn, {width: '10%'}]}
        >
          <Icons.ArrowBackIcon style={{color: 'white'}}/>
        </Button>
      </HStack>

      <FormControlErrorDiv errorMsg={errorMsg} />
      {[
        [7, 8, 9],
        [4, 5, 6],
        [1, 2, 3],
      ].map((row, rowIndex) => {
        return (
          <HStack justifyContent={'space-between'} key={rowIndex}>
          {row.map((number, index) => {
            return (
              <Button
                colorScheme={'primary'}
                variant={'unstyled'}
                key={index}
                isLoading={isLoading}
                onPress={() => addNumber(`${number}`)}
                style={[
                  studentAbsenceScreenStyle.searchInputBtnWhite,
                  studentAbsenceScreenStyle.searchInputBtn,
                  {width: '32%'}
                ]}
              >
                <Text style={{fontSize: 20}}>{number}</Text>
              </Button>
            )
          })}
          </HStack>
        )
      })}

      <HStack justifyContent={'space-between'}>
        <Button
          colorScheme={'primary'}
          variant={'unstyled'}
          onPress={() => addNumber(`0`)}
          isLoading={isLoading}
          style={[
            studentAbsenceScreenStyle.searchInputBtnWhite,
            studentAbsenceScreenStyle.searchInputBtn,
            {width: '23%'}
          ]}
        >
          <Text style={{fontSize: 20}}>0</Text>
        </Button>

        <Button
          colorScheme={'danger'}
          startIcon={<Icons.CloseIcon />}
          onPress={() => setStudentId('')}
          isLoading={isLoading}
          style={[
            studentAbsenceScreenStyle.searchInputBtn,
            {width: '35%'}
          ]}
        >
          <Text style={{fontWeight: 'bold', color: 'white', fontSize: 20}}>Clear</Text>
        </Button>

        <Button
          colorScheme={'primary'}
          startIcon={<Icons.SearchIcon />}
          onPress={() => onSearch(studentId)}
          isLoading={isLoading}
          style={[
            studentAbsenceScreenStyle.searchInputBtn,
            {width: '35%'}
          ]}
        >
          <Text style={{fontWeight: 'bold', color: 'white', fontSize: 20}}>Submit</Text>
        </Button>
      </HStack>
    </View>
  )
}

export default StudentIDSearchPanel;
