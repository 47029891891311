import {NativeBaseProvider} from "native-base";
import {NavigationContainer} from '@react-navigation/native';
import Router from './src/router';
import AppProvider from './src/components/AppProvider';
import Sentry from './src/components/common/Sentry';


export default function App() {

  Sentry.init();

  return (
    <NativeBaseProvider>
      <NavigationContainer>
        <AppProvider>
          <Router />
        </AppProvider>
      </NavigationContainer>
    </NativeBaseProvider>
  );
}


