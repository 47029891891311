import {StyleSheet} from 'react-native';

const red = '#d8242f';

export const screenStyle = StyleSheet.create({
  fullScreen: {
    width: '100%',
    height: '100%',
  },

  redScreen: {
    backgroundColor: red,
  },

  redScreenText: {
    color: 'white',
  },

  h2: {
    fontSize: 30,
    lineHeight: 30,
  },

  h3: {
    fontSize: 26,
    lineHeight: 26,
  },

  h4: {
    fontSize: 18,
    lineHeight: 18,
  },

  h5: {
    fontSize: 16,
    lineHeight: 16,
  },
  small: {
    fontSize: 12,
    lineHeight: 12,
  }

})

export const homeScreenStyle = StyleSheet.create({
  screen: {
    ...screenStyle.fullScreen,
    ...screenStyle.redScreen
  },
});

export const studentAbsenceScreenStyle = StyleSheet.create({
  screen: {
    ...screenStyle.fullScreen,
    ...screenStyle.redScreen,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
  },
  header: {},
  text: screenStyle.redScreenText,

  searchPanel: {
    width: '80%',
    marginTop: 24,
  },

  searchContentPanel: {
    marginTop: 24,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    width: '100%',
  },

  searchTypeBtn: {
    width: '50%',
    borderRadius: 0,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#f2f2f2',
  },

  searchTypeBtnActive: {
    backgroundColor: '#e2e2e2',
  },

  searchInputWrapper: {
    width: '100%',
  },

  searchInputError: {
    backgroundColor: 'red',
    color: 'white',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },

  searchInputDiv: {
    backgroundColor: 'white',
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 18,
    paddingRight: 18,
  },

  searchBtn: {
    backgroundColor: 'rgb(240, 173, 78)',
    borderRadius: 0,
  },

  searchInputBtnWhite: {
    backgroundColor: '#f2f2f2',
  },

  searchInputBtn: {
    marginTop: 18,
    paddingTop: 20,
    paddingBottom: 20,
  },

  styleSelectBtn: {
    width: '45%'
  }
});

