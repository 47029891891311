import {Provider, useSelector} from 'react-redux';
import store, {RootState, useAppDispatch} from '../redux/makeReduxStore';
import AppAuthPanel from './common/AppAuthPanel';
import {useEffect} from 'react';
import LocalStorageService, {
  LOCAL_STORAGE_APP_TOKEN_NAME,
  LOCAL_STORAGE_DEVICE_INFO_NAME, LOCAL_STORAGE_MGG_APP_IS_PROD, LOCAL_STORAGE_SELECTED_SCREEN
} from '../services/LocalStorageService';
import {setApp} from '../redux/reduxers/app.slice';
import TestDiv from './common/TestDiv';

type iApp = {
  children: any
}
const App = ({children}: iApp) => {
  const app = useSelector((state: RootState) => state.app);
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([
        LocalStorageService.getItem(LOCAL_STORAGE_APP_TOKEN_NAME),
        LocalStorageService.getItem(LOCAL_STORAGE_DEVICE_INFO_NAME),
        LocalStorageService.getItem(LOCAL_STORAGE_SELECTED_SCREEN),
        LocalStorageService.getItem(LOCAL_STORAGE_MGG_APP_IS_PROD),
      ])
      .then((resp) => {
        dispatch(setApp({...app, appToken: resp[0], deviceInfo: resp[1], selectedScreen: resp[2], isProd: resp[3]}));
      })
  }, [dispatch])

  if(`${app.appToken || ''}`.trim() === '') {
    return <AppAuthPanel />
  }

  return (
    <>
      {children}
      <TestDiv />
    </>
  )
}


type iAppProvider = {
  children: any
}
const AppProvider = ({children}: iAppProvider) => {
  return (
    <Provider store={store}>
      <App>{children}</App>
    </Provider>
  )
}

export default AppProvider;
