import {AxiosRequestConfig} from 'axios';
import iMggAppDevice from '../../types/Settings/iMggAppDevice';
import AppService from '../AppService';

const endPoint = '/appDevice';

type iDeviceInit = {device: iMggAppDevice, token: string};
const init = (deviceId: string, code: string, config: AxiosRequestConfig = {} ): Promise<iDeviceInit> => {
  return AppService.post(`${endPoint}/init`, {deviceId, code}, config).then(resp => resp.data);
}

const MggAppDeviceService = {
  init,
}

export default MggAppDeviceService;
