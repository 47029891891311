import Box from '../../components/framework/Box';
import Select, {SelectItem} from '../../components/framework/Select';
import {Icons} from '../../components/framework/Icon';
import {useEffect, useState} from 'react';
import iCampusDisplayLocation from '../../types/CampusDisplay/iCampusDisplayLocation';
import Toaster from '../../components/common/Toaster';
import CampusDisplayLocationService from '../../services/CampusDisplay/CampusDisplayLocationService';
import {useToast} from 'native-base';
import Spinner from '../../components/framework/Spinner';

type iCampusDisplayLocationSelector = {
  selectedLocationId: string | null;
  onLocationSelected: (locationId: string) => void;
}
const CampusDisplayLocationSelector = ({selectedLocationId, onLocationSelected}: iCampusDisplayLocationSelector) => {
  const toaster = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState<iCampusDisplayLocation[]>([]);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    CampusDisplayLocationService.getAll({
        where: JSON.stringify({isActive: true}),
        perPage: 99999,
      })
      .then(resp => {
        if (isCanceled) { return }
        setLocations(resp.data || []);
      })
      .catch(err => {
        if (isCanceled) { return }
        Toaster.showApiErr(err, toaster);
      })
      .finally(() => {
        if (isCanceled) { return }
        setIsLoading(false);
      })
    return () => {
      isCanceled = true;
    }
  }, []);

  const getContent = () => {
    if (isLoading === true) {
      return <Spinner />
    }
    return (
      <Select
        selectedValue={selectedLocationId}
        minWidth="200"
        accessibilityLabel="Choose Service"
        placeholder="Choose Service"
        _selectedItem={{
          bg: "teal.600",
          endIcon: <Icons.CheckIcon size="5" />
        }}
        mt={1}
        onValueChange={itemValue => onLocationSelected(itemValue)}>
        {locations.map(location => {
          return (
            <SelectItem label={location.name} value={location.id} key={location.id} />
          )
        })}
      </Select>
    )
  }

  return <Box>{getContent()}</Box>;
}

export default CampusDisplayLocationSelector;
