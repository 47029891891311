import {useEffect, useState} from 'react';
import VStack from '../framework/VStack';
import FormControl, {FormControlLabel} from '../framework/FormControl';
import Input from '../framework/Input';
import View from '../framework/View';
import Logo from '../Logo';
import Center from '../framework/Center';
import {screenStyle} from '../../AppStyles';
import Button from '../framework/Button';
import {Icons} from '../framework/Icon';
import LocalStorageService, {
  LOCAL_STORAGE_API_END_POINT,
  LOCAL_STORAGE_APP_TOKEN_NAME,
  LOCAL_STORAGE_DEVICE_INFO_NAME, LOCAL_STORAGE_MGG_APP_IS_PROD
} from '../../services/LocalStorageService';
import MggAppDeviceService from '../../services/Settings/MggAppDeviceService';
import {useToast} from 'native-base';
import Toaster from './Toaster';
import Text from '../framework/Text';
import {RootState, useAppDispatch} from '../../redux/makeReduxStore';
import {setApp} from '../../redux/reduxers/app.slice';
import {useSelector} from 'react-redux';
import {DEFAULT_END_POINT} from '../../services/AppService';
import PingService from '../../services/PingService';

type iData = {
  deviceId?: string;
  code?: string;
  endPointUrl?: string;
}
const init: iData = {}
const AppAuthPanel = () => {
  const app = useSelector((state: RootState) => state.app);
  const toaster = useToast();
  const [formData, setFormData] = useState<iData>(init);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMap, setErrorMap] = useState<{[key: string]: string}>({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([
      LocalStorageService.getItem(LOCAL_STORAGE_DEVICE_INFO_NAME),
      LocalStorageService.getItem(LOCAL_STORAGE_API_END_POINT),
    ])
      .then(resp => {
        setFormData({
          ...formData,
          ...(`${resp[0]?.deviceId || ''}`.trim() !== '' ? {deviceId: resp[0].deviceId}: {}),
          ...(`${resp[1] || ''}`.trim() !== '' ? {endPointUrl: `${resp[1] || ''}`.trim()}: {endPointUrl: DEFAULT_END_POINT}),
        })
      })
  }, []);

  const preCheck = () => {
    const error: {[key: string]: string} = {};
    if (`${formData.endPointUrl || ''}`.trim() === '') {
      error.endPointUrl = "End Point Url is required.";
    }
    if (`${formData.deviceId || ''}`.trim() === '') {
      error.deviceId = "Device ID is required.";
    }
    if (`${formData.code || ''}`.trim() === '') {
      error.code = "Code is required.";
    }
    setErrorMap(error);
    return Object.keys(error).length === 0;
  }

  const submit = () => {
    if (preCheck() !== true) {
      Toaster.showErr(`Error in submitting form, check it below...`, toaster);
      return;
    }

    setIsLoading(true);
    Promise.all([
      MggAppDeviceService.init(`${formData.deviceId || ''}`.trim(), `${formData.code || ''}`.trim()),
      PingService.ping(),
    ])
      .then(res => {
        const {token, device} = res[0];
        Promise.all([
            LocalStorageService.setItem(LOCAL_STORAGE_APP_TOKEN_NAME, token),
            LocalStorageService.setItem(LOCAL_STORAGE_DEVICE_INFO_NAME, device),
            LocalStorageService.setItem(LOCAL_STORAGE_MGG_APP_IS_PROD, res[1].isProd === true),
          ])
          .then((resp) => {
            dispatch(setApp({...app, appToken: token, deviceInfo: device, isProd: res[1].isProd === true}));
          })
      })
      .catch(err => {
        Toaster.showApiErr(err, toaster);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const getErrMsg = (fieldName: string) => {
    if (!(fieldName in errorMap)) {
      return null;
    }
    return (
      <Text style={{
        ...screenStyle.redScreenText,
        color: '#f97316',
        fontSize: 16,
      }}>
        {errorMap[fieldName]}
      </Text>
    )
  }

  const getFormField = (fieldName: string, label: string, placeHolder: string, type: 'text' | 'password' = 'text', onChange?: (value: string) => Promise<void>) => {
    return (
      <FormControl isRequired>
        <FormControlLabel _text={{ ...screenStyle.redScreenText, bold: true, fontSize: 18 }}>
          {label}
        </FormControlLabel>
        <Input
          type={type}
          value={fieldName in formData ? formData[fieldName] : ''}
          style={{backgroundColor: 'white', paddingTop: 12, paddingBottom: 12, fontSize: 18, borderColor: '#ffffff'}}
          placeholder={placeHolder}
          onChangeText={value => {
            if (onChange) {
              onChange(value).then(() => {
                setFormData({ ...formData,
                  [fieldName]: value
                });
              })
            }
            setFormData({ ...formData,
              [fieldName]: value
            });
          }}
        />
        {getErrMsg(fieldName)}
      </FormControl>
    )
  }

  return (
    <View style={[screenStyle.redScreen, {width: '100%', height: '100%'}]}>
      <Center flex={1}>
        <Logo useSplash width={300} height={300}/>
        <VStack width={'40%'} minWidth={300} maxWidth={600}>
          {getFormField('endPointUrl', 'EndPoint', `The API End Point, ie: ${DEFAULT_END_POINT}`, 'text', (newUrl) => LocalStorageService.setItem(LOCAL_STORAGE_API_END_POINT, newUrl))}
          {getFormField('deviceId', 'Device ID', 'Device ID')}
          {getFormField('code', 'Code', 'Device Code', 'password')}
          <Text style={{
            ...screenStyle.redScreenText,
            fontSize: 16,
            textAlign: 'right',
          }}>
            Contact MGG IT, if you don't know it
          </Text>
          <Button
            isLoading={isLoading}
            onPress={() => submit()}
            leftIcon={<Icons.Submit />}
            mt="5"
            colorScheme="cyan"
          >
            Submit
          </Button>
        </VStack>
      </Center>
    </View>
  )
}

export default AppAuthPanel;
